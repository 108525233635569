<template>
  <div>
    <RouterView name="header"/>
    <RouterView/>
    <IndeterminateProgress v-if="loadingCounter > 0" class="progress-indicator"/>
  </div>
</template>

<style lang="scss" src="./assets/css/global.scss"></style>

<script>
import { RouterView } from 'vue-router'
import IndeterminateProgress from '@/components/IndeterminateProgress'

export default {
  name: 'App',
  components: { IndeterminateProgress, RouterView },
  data () {
    return {
      loadingCounter: 0
    }
  },
  mounted () {
    window.addEventListener('loading-start', () => {
      this.loadingCounter++
    })
    window.addEventListener('loading-end', () => {
      this.loadingCounter--
    })
  }
}
</script>

<style>
.progress-indicator {
  position: fixed;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
}

</style>
