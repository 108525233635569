import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

axios.defaults.baseURL = '/api/v1'
axios.interceptors.response.use(function (response) {
  window.dispatchEvent(new Event('loading-end'))
  return response
}, function (error) {
  if (401 === error.response.status) {
    localStorage.removeItem('token')
  }
  window.dispatchEvent(new Event('loading-end'))
  return Promise.reject(error)
})

axios.interceptors.request.use(function (config) {
  window.dispatchEvent(new Event('loading-start'))
  return config
}, function (error) {
  return Promise.reject(error)
})

if (localStorage.getItem('token')) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
}

createApp(App).use(store).use(router).mount('#app')
