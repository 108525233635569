<template>
  <div id="progressContainer" class="indeterminate-progress">
    <div id="primaryProgress" class="primary-progress indeterminate"></div>
  </div>
</template>

<script>
export default {
  name: 'IndeterminateProgress'
}
</script>

<style scoped>
.indeterminate-progress {
  display: block;
  width: 200px;
  position: relative;
  overflow: hidden;
}

.indeterminate-progress,
.primary-progress,
.indeterminate::after {
  height: 4px;
}

.indeterminate-progress,
.indeterminate::after {
  background: var(--card-background);
}

.primary-progress {
  -webkit-transform-origin: left center;
  transform-origin: left center;
  transform: scaleX(0);
  will-change: transform;
  background: var(--primary-color);
}

.primary-progress.indeterminate {
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-animation: indeterminate-bar 2s linear infinite;
  animation: indeterminate-bar 2s linear infinite;
}

.primary-progress.indeterminate::after {
  content: "";
  transform-origin: center center;
  animation: indeterminate-splitter 2s linear infinite;
}

@keyframes indeterminate-bar {
  0% {
    transform: scaleX(1) translateX(-100%);
  }
  50% {
    transform: scaleX(1) translateX(0%);
  }
  75% {
    transform: scaleX(1) translateX(0%);
    animation-timing-function: cubic-bezier(.28, .62, .37, .91);
  }
  100% {
    transform: scaleX(0) translateX(0%);
  }
}

@keyframes indeterminate-splitter {
  0% {
    transform: scaleX(.75) translateX(-125%);
  }
  30% {
    transform: scaleX(.75) translateX(-125%);
    animation-timing-function: cubic-bezier(.42, 0, .6, .8);
  }
  90% {
    transform: scaleX(.75) translateX(125%);
  }
  100% {
    transform: scaleX(.75) translateX(125%);
  }
}
</style>
