import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    token: localStorage.getItem('token') || null,
    theme: localStorage.getItem('theme') || 'auto',
    orders: [],
    ordersCurrentPage: 1,
    ordersPageTotalCount: 1,
    ordersTotal: null,
    ordersTotalCount: null,
    ordersTotalPaid: null,
    ordersTotalPaidCount: null,
    event: {}
  },
  getters: {
    isLoggedIn: state => !!state.token
  },
  mutations: {
    login (state, authData) {
      state.token = authData.token
      localStorage.setItem('token', authData.token)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + authData.token
    },
    logout (state) {
      state.token = null
      localStorage.removeItem('token')
    },
    setTheme (state, theme) {
      document.documentElement.classList.remove('theme-light', 'theme-dark')
      state.theme = theme
      localStorage.setItem('theme', theme)
      if (['dark', 'light'].includes(theme)) {
        document.documentElement.classList.add(`theme-${theme}`)
      }
    },
    setEvent (state, event) {
      state.event = event
    },
    mergeOrders (state, data) {
      let orders = state.orders.concat()
      data.forEach(info => {
        let order = orders.find(order => order.id === info.id)
        if (!order) {
          orders.push(info)
        } else {
          Object.assign(order, info)
        }
      })
      orders.sort((a, b) => {
        return b.id - a.id
      })
      state.orders = orders
    },
    setOrdersInfo (state, info) {
      state.ordersCurrentPage = parseInt(info['x-pagination-current-page'])
      state.ordersPageTotalCount = parseInt(info['x-pagination-page-count'])
      state.ordersTotal = parseInt(info['x-total'])
      state.ordersTotalCount = parseInt(info['x-pagination-total-count'])
      state.ordersTotalPaid = parseInt(info['x-total-paid'])
      state.ordersTotalPaidCount = parseInt(info['x-total-paid-count'])
    }
  },
  actions: {
    login ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios.post('/login', formData)
          .then(function (response) {
            commit('login', response.data)
            resolve()
          })
          .catch(function (e) {
            reject(e)
          })
      })
    },
    logout ({ commit }) {
      commit('logout')
      window.location.href = '/'
    },
    loadEventInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/event')
          .then(function (response) {
            commit('setEvent', response.data)
            resolve()
          })
          .catch(function (e) {
            reject(e)
          })
      })
    },
    loadOrders ({ commit }, page) {
      return new Promise((resolve, reject) => {
        axios.get('/orders', {
          params: {
            page: page || 1
          }
        })
          .then(function (response) {
            commit('setOrdersInfo', response.headers)
            commit('mergeOrders', response.data)
            resolve()
          })
          .catch(function (e) {
            reject(e)
          })
      })
    },
    markOrderAsPaid ({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        axios.patch(`/orders/${orderId}`, {
          isPaid: true
        })
          .then(function (response) {
            commit('mergeOrders', [response.data])
            resolve()
          })
          .catch(function (e) {
            reject(e)
          })
      })
    },
    markOrderAsUnpaid ({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        axios.patch(`/orders/${orderId}`, {
          isPaid: false
        })
          .then(function (response) {
            commit('mergeOrders', [response.data])
            resolve()
          })
          .catch(function (e) {
            reject(e)
          })
      })
    }
  },
  modules: {}
})
