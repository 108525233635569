import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      default: () => import('../views/PromoСodeView'),
      header: () => import('../components/TheHeader'),
    },
    beforeEnter: () => {
      store.dispatch('loadEventInfo')
    },
  },
  {
    path: '/orders',
    name: 'orders',
    components: {
      default: () => import('../views/OrdersView'),
      header: () => import('../components/TheHeader'),
    },
    beforeEnter: () => {
      store.dispatch('loadEventInfo')
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {

  if (store.getters.isLoggedIn) {
    if (to.name === 'login') {
      return { name: 'home' }
    }
  } else if (to.name !== 'login') {
    return { name: 'login' }
  }
})

export default router
